<style lang="scss" scoped>

  .carousel-index {
    .slide {
      margin-top:100px;
      height: 500px;
      display: flex;
      gap: 20px;
      flex-direction: column;
      justify-content: flex-end;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      // background-image: linear-gradient(180deg, transparent, black);
      padding: 50px 0;
      color: white;
      // font-family: 'Open Sans', sans-serif;
      position: relative;
      z-index: 1;
      letter-spacing: 2px;

      &__title {
        font-weight: 700;
        font-size: 4rem;
      }

      &__description {
        font-weight: 500;
        font-size: 1.125rem;
      }

      &__link {
        color: #FBD784;
      }
    }
    button.VueCarousel-dot.VueCarousel-dot--active {
      background-color: #fbd784 !important;
    }
  }

</style>

<template lang="pug">

  .carousel-index
    carousel(:per-page="1" :autoplay="true" :autoplay-timeout="3000" :loop="true")
      template(v-for="(slide, index) in slides")
        slide.slide(:key="index" :style="getBackground(index)")
          Title(side='full') {{ slide.category }}
          h1.slide__title {{ slide.title }}
          p.slide__description {{ slide.description }}
          router-link.slide__link(:to="slide.link") Ver mais

</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'CarouselNews',

  props: {
    slides: { type: Array, required: true }
  },

  components: {
    Carousel, Slide
  },

  methods: {
    getBackground(index) {
      if (!this.slides[index] || this.slides[index].image.length === 0) return false;
      const image = `${process.env.VUE_APP_API}${this.slides[index].image[0].url}`;
      return {
        backgroundImage: `linear-gradient(180deg, transparent, black), url(${image}) `
      };
    }
  }
};

</script>
