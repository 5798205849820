<style lang="scss">

.home-index {
  text-align: center;
}

</style>

<template lang="pug">

  .home-index
    .container
      Presentation

      carousel-news(:slides="sliders"  v-if="user.id")

      CardNews(v-for="(item,key) in home" :key="key" :flex-order="checkOrder(key)"  :card='item' v-if="user.id")
</template>

<script>

import CarouselNews from './components/content/carousel-news.vue';
import CardNews from './components/content/card-news.vue';
import Presentation from './components/content/presentation.vue';

export default {
  name: 'Home',

  components: {
    CarouselNews,
    Presentation,
    CardNews
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created() {
    this.getHome();
    this.getSliders();
  },
  methods: {
    checkOrder(n) {
      if (n % 2 === 0) {
        return 'left';
      }
      return 'right';
    },
    getSliders() {
      this.$api.get('/sliders')
        .then((response) => {
          this.sliders = response.data;
        });
    },

    getHome() {
      this.$api.get('/homes')
        .then((response) => {
          this.home = response.data;
        });
    }
  },
  data() {
    return {
      sliders: [],
      home: [],
      cards: [
        {
          category: 'Nessa edição',
          title: 'Programa Quero Ser P.O.',
          description: '<p>Como esse programa incentivou e capitou diversos profissionais para várias unidades da compania.</p> <p>Aumentando a qualidade dos nosso projetos e produtos</p>',
          image: 'https://cdn-1.motorsport.com/images/amp/6n9kK8XY/s1000/daniel-ricciardo-mclaren-mcl35.jpg',
          link: '/'
        },
        {
          category: 'Nessa edição',
          title: 'Programa Quero Ser P.O.',
          description: '<p>Como esse programa incentivou e capitou diversos profissionais para várias unidades da compania.</p> <p>Aumentando a qualidade dos nosso projetos e produtos</p>',
          image: 'https://media.racingonline.com.br/uploads/2021/09/20210903_F1_Holanda_CharlesLeclerc_01_Ferrari-1024x683.jpeg',
          link: '/'
        }
      ],
      carousel: [
        {
          category: 'LWSA3',
          title: 'Campeaonato apertado',
          description: 'Hamilton vê seu domínio ameaçado, Verstappen está por cima. Literalmente.',
          image: 'https://sociedadeonline.com/wp-content/uploads/2021/09/750_verstappen-hamilton-formula-1-gp-da-italia-monza-esporte_2021912151336678-1.jpg',
          link: '/'
        },
        {
          category: 'LWSA3',
          title: 'Dobradinha McLaren',
          description: 'Riccardo implacável, Norris na cola.',
          image: 'https://cdn-wp.thesportsrush.com/2021/07/658942e7-personal-vlog-youtube-thumbnail-4.jpg',
          link: '/'
        },
        {
          category: 'LWSA3',
          title: 'Campeaonato apertado',
          description: 'Hamilton vê seu domínio ameaçado, Verstappen está por cima. Literalmente.',
          image: 'https://images.autosport.pt/2021/09/f9fd3ab7-be4e-45f8-85f7-2dfe93617a6e.jpg',
          link: '/'
        }
      ]
    };
  }
};

</script>
