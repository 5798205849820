<style lang="scss" scoped>

.card-news {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin: 120px 0;

  @include tablet {
    flex-direction: column;
  }

  &__img {
    //width: 50%;
    //height: 720px;
    width: 100%;
    max-height: 670px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include tablet {
      height: 670px;
    }
  }

  &__content {
    //width: 50%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 0 50px;
    gap: 20px;
    font-family: $primary-font;

    &-title {
      font-size: 4rem;
      font-weight: 700;
    }

    &-description {
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 26px;

      p {
        margin: 10px 0;
      }
    }

    &-link {
      color: $color-secondary;
      text-decoration: none;
    }
  }
}

</style>

<template lang="pug">

  .card-news
    .card-news__img(:style="getBackground")
    .card-news__content
      Title {{ card.category }}
      Heading {{ card.title }}
      p.card-news__content-description(v-html="card.description")

      template(v-if="cardTypeTest")
        a.card-news__content-link(:href="card.link" target="_blank") Ver Mais

      template(v-else)
        router-link.card-news__content-link(:to="card.link") Ver Mais 2

</template>

<script>

export default {
  name: 'CardNews',

  props: {
    card: { type: Object, required: true },
    flexOrder: { type: String, required: false, default: 'left' },
    typeCard: { type: String, required: false, default: 'card' }
  },
  created() {
    console.log('THIS CARD', this.card);
    console.log('process.env.VUE_APP_API', process.env.VUE_APP_API);
  },
  computed: {
    getBackground() {
      if (!this.card || this.card.image.length === 0) return false;
      const image = `${process.env.VUE_APP_API}${this.card.image[0].url}`;
      console.log('GET BACKGROUND', image);
      return {
        backgroundImage: `url(${image})`,
        order: this.order
      };
    },

    cardTypeTest() {
      return this.typeCard === 'pdf';
    },

    order() {
      switch (this.flexOrder) {
        case 'left':
          return 0;
        case 'right':
          return 1;
        default:
          return 0;
      }
    }
  }
};

</script>
