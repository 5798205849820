<style lang="scss">

.presentation {
  width: 100%;
  height: calc(100vh - 120px);
  // background: url("../../../../../assets/img/home/predio-locaweb.png") no-repeat 750px center;
  // background-size: 760px auto;

  @include tablet {
    // height: 280px !important;
    padding: 0 60px;
  }

  .presentation__content {
    text-align: left;
    position: relative;
    top: 150px;
    z-index: 100 !important;

    @include tablet {
      top: 70px;
    }

    &-title {
      font-size: 4.5rem;
      line-height: 82px;
      font-weight: 500;
      margin-top: 30px;
      letter-spacing: 4px;
      font-family: $title-font;
      z-index: 1;
       -webkit-text-stroke: 1px rgb(14, 12, 12); /* width and color */

      @include tablet {
        font-size: 50px;
        line-height: 60px;
      }

      span {
        display: block;
        font-weight: bold;
      }
    }
    &-roll {
      text-align: center;
      font-weight: 100;
      height: 20px;
      position: absolute;
      margin: 0 auto;
      left: 50%;
      top: 300px;

      .arrow {
        width: 16px;
        vertical-align: middle;
        margin-left: 5px;
      }
    }
  }

  &__img-builds {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    animation: down-up 1s ease-out;
  }

  &__img-lady-left {
    bottom: 0;
    right: 310px;
    position: absolute;
    width: 290px;
    z-index: 9;
    animation: shows-up 1.3s ease-in;

    @include tablet {
      width: 200px;
      right: 230px;
    }
  }

  &__img-lady-right {
    bottom: 0;
    right: 30px;
    position: absolute;
    width: 380px;
    animation: shows-up 2s ease-in;

    @include tablet {
      width: 280px;
    }
  }

  &__img-line {
    position: absolute;
    right: -212px;
    bottom: -40px;
    width: 1000px;
    z-index: 99;
    animation: slide-in 1s ease-out;

    @include tablet {
      right: -94px;
      bottom: -16px;
      width: 630px;
    }
  }

  @keyframes down-up {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @keyframes slide-in {
    0% {
      transform: translateX(100px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  @keyframes shows-up {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

}

</style>

<template lang="pug">

  .presentation
    //.presentation__img(:style="getBackground")
    .presentation__content
      Title Explore nossa história
      //span.presentation__content-subtitle Explore nossa história
      h1.presentation__content-title <span>Livro</span> da Cultura 2022
      //p.presentation__content-roll Role a página
      p.presentation__content-roll(v-if="user.id") Role a página
        img.arrow(src="@/assets/img/editions/down-arrow.svg")
      p(v-else) Acesso restrito - Você precisa efetuar o login para acessar a página

    img.presentation__img-builds(src="@/assets/img/home/predios.webp")
    img.presentation__img-lady-left(src="@/assets/img/home/branca.png")
    img.presentation__img-lady-right(src="@/assets/img/home/preta.png")
    img.presentation__img-line(src="@/assets/img/home/linha.png")

</template>

<script>

export default {
  name: 'Presentation',
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    getBackground(index) {
      return {
        backgroundImage: `linear-gradient(180deg, transparent, black), url(${this.slides[index].image}) `
      };
    }
  }

};

</script>
